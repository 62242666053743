<mat-toolbar class="nav">
  <mat-toolbar-row>
    <!-- <span class="logo" ><img mat-flat-button src="../../../assets/img/scala-salon-peluqueria-y-bellza-logo-nav.png" alt=""></span> -->
    <span class="logo" >
      <a mat-flat-button href="#home"><img src="../../../assets/img/scala-salon-peluqueria-y-bellza-logo-nav.png" alt=""></a>
    </span>
    <span class="spacer"></span>
    <a class="desk-links" mat-flat-button href="#home">inicio</a>
    <a class="desk-links" mat-flat-button href="#nosotros">Sobre nosotros</a>
    <a class="desk-links" mat-flat-button href="#contacto">Contacto</a>
    <a class="desk-links" mat-flat-button href="#galeria">Galeria</a>
    <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"></button>
    <mat-icon>menu</mat-icon>
    </button> -->
  </mat-toolbar-row>
  <mat-toolbar-row class="cell-nav-section">
    <a class="cell-links" mat-flat-button href="#home">inicio</a>
    <a class="cell-links" mat-flat-button href="#nosotros">Sobre nosotros</a>
    <a class="cell-links" mat-flat-button href="#contacto">Contacto</a>
    <a class="cell-links" mat-flat-button href="#galeria">Galeria</a>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <p>Tel: 988-22-60-08 - NIF: Avenida de Portugal, 172</p>
  </mat-toolbar-row>
</mat-toolbar>
