<div class="home-container">
  <div class="bg"></div>
  <div class="bg-2"></div>
  <img class="logo" src="../../../assets/img/scala-salon-peluqueria-y-bellza-logo.png" alt="scala-salon-peluqueria-y-bellza-logo">
  <h1>Scala</h1>
  <h2>Peluquería y salon de belleza</h2>
  <table>
    <tr>
      <td class="type-data">Direccion</td>
      <td>Avenida de Portugal - 172 - Orense</td>
    </tr>
    <tr>
      <td>Telefono</td>
      <td>988-22-60-08</td>
    </tr>
  </table>
</div>
