<div class="aboutUs-container">
  <div class="escrito">
    <h1><strong>Scala, peluquería & belleza</strong></h1>
    <p>
      Con mas de 10 años de experiencia <strong>Scala</strong> nos dedicamos al trabajo de <strong>Peluquería </strong> y <Strong>Salón de belleza</Strong>
      a un nivel profesional tanto para el día a día como para <strong>eventos, fiestas, Bodas</strong> y todos los retos que nos propongas.
      Nuestros servicios cuentan con la mayor exigencia y dedicación de nuestro equipo humano los <strong>mejores productos</strong>
      para el tratamiento del cabello y la belleza.
    </p>
    <p>
      Ofrecemos un amplio catálogo de servicios de <strong>peluquería y estética en Ourense</strong> como <strong>peinados, cortes de pelo, color, tratamientos
      capilares, depilación, manicura, pedicura, maquillaje, tratamientos faciales y corporales... </strong>
      que ejecutamos con amplia experiencia para atender a todas tus necesidades.
    </p>
    <p>
      Nuestro ímpetu de seguir adelante, mejorar, ofrecer el mejor servicio y valores a nuestros clientes día a día es lo que empuja a nuestro equipo a estar siempre
       contigo y ofrecerte las últimas tendencias del mundo de la belleza.
    </p>
  </div>
  <div class="aboutUs-Gallery">
    <img src="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-cortes-de-pelo.png" alt="">
    <img src="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-servicio-depeluqueria-y-belleza.png" alt="">
    <img src="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-servicios-consulta.png" alt="">
  </div>
</div>
