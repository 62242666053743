<div class="contactUS-container">
  <div class="escritos">
    <h1>Contacta con Scala, tu peluquería en Ourense.</h1>
    <h2>Pide cita previa en nuestro salon de peluquería y belleza</h2>
    <p>Si quieres más información sobre nuestros servicios de <strong>Peluquería
      y Belleza</strong> en <strong>Ourense</strong> no dudes en visitarnos para aclarar tus dudas o también
      puedes contactarnos a través de <strong>teléfono, Email</strong> o mediante nuestra
      página en <strong>facebook</strong>, ahora también en <strong>WhatsApp!</strong>.
  </div>
  <h2 class="contat">Forma de contactarnos</h2>
  <table class="contactos">
    <tr>
      <td><mat-icon>my_location</mat-icon></td>
      <td class="aclaraciones">Direccion</td>
      <td>Avenida de Portugal, 172, Orense</td>
    </tr>
    <tr>
      <td><mat-icon>call</mat-icon></td>
      <td class="aclaraciones">Telefono</td>
      <td>988-22-60-08</td>
    </tr>
    <tr>
      <td><mat-icon>facebook</mat-icon></td>
      <td class="aclaraciones">Facebook</td>
      <td><a href="https://www.facebook.com/ScalaPeluqueria/?ref=page_internal">Contacta con nosotros por Facebook!</a></td>
    </tr>
    <tr>
      <td><i class="fab fa-whatsapp fa-2x"></i></td>
      <td class="aclaraciones">WhatsApp</td>
      <td>988-22-60-08</td>
    </tr>
    <tr>
      <td><mat-icon>mail_outline</mat-icon></td>
      <td class="aclaraciones">Email</td>
      <td>scala.peluqueria@galicia.com</td>
    </tr>
  </table>
  <h2 class="contat">Horarios</h2>
  <table class="horarios">
    <tr>
      <td style="text-align: center;">Días</td>
      <td style="text-align: center;">Horarios</td>
    </tr>
    <tr>
      <td >Lunes a Jueves</td>
      <td>Mañanas: 9:00 a 13:30 - Tardes: 16:00 a 20:00</td>
    </tr>
    <tr>
      <td >Viernes y víspera de fetivos</td>
      <td>9:00 a 20:00</td>
    </tr>
    <tr>
      <td >Sábados</td>
      <td>9:00 a 16:00</td>
    </tr>
  </table>
  <h2 class="contat">Mapa</h2>
  <iframe class="mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11798.214463289323!2d-7.8698004!3d42.3307188!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x94ee905c4c11a004!2sScala!5e0!3m2!1ses!2ses!4v1599468761859!5m2!1ses!2ses" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
