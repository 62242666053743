<div class="gallery-container">
  <h1>Galeria</h1>
  <mat-tab-group>
    <mat-tab label="Salon">
      <div class="img-gallery" id="salon">
        <a href="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-entrada.png" class="img-1">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-servicios-consulta.png" class="img-2">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-cortes-de-pelo.png" class="img-3">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-de-peluqueria-salon-de-belleza.png" class="img-4">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-servicio-depeluqueria-y-belleza.png" class="img-5">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/salon/scala-peluqueria-&-belleza-salon-escaparate.png" class="img-6">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
    </div>
    </mat-tab>
    <mat-tab label="Productos">
      <div class="img-gallery" id="productos">
        <a href="../../../assets/img/gallery/peinados/scala-peinados-&-belleza-cortes-de-pelos-teñidos-rubios.jpg" class="img-1">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/scala-peinados-&-belleza-cortes-de-pelos-teñidos.jpg" class="img-2">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/scala-peinados-&-belleza-peinados-ocacion-fiestas-eventos.jpg" class="img-3">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/scala-peinados-&-belleza-cortes-de-pelos-teñidos-rubios-claros.jpg" class="img-4">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/scala-peinados-&-belleza-cortes-de-pelos-teñidos-castaños.jpg" class="img-5">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/scala-peinados-&-belleza-cortes-de-pelos-eventos-fiestas-eventos.jpg" class="img-6">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
    </div>
  </mat-tab>
    <mat-tab label="Trabajos">
      <div class="img-gallery" id="trabajos">
        <a href="../../../assets/img/gallery/peinados/gallery-1.jpg" class="img-1">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/gallery-2.jpg" class="img-2">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/gallery-3.jpg" class="img-3">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/gallery-4.jpg" class="img-4">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/gallery-5.jpg" class="img-5">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/peinados/gallery-6.jpg" class="img-6">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
    </div>
  </mat-tab>
    <mat-tab label="Peinados y tintes">
      <div class="img-gallery" id="peinados">
        <a href="../../../assets/img/gallery/trabajos/scala-peinados-&-belleza-peinados-eventos-salon-ourense.jpg" class="img-1">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/trabajos/scala-peinados-&-belleza-peinados-fiestas-salon-ourense.jpg" class="img-2">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/trabajos/scala-peinados-&-belleza-peinados-formal-salon-ourense.jpg" class="img-3">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/trabajos/scala-peinados-&-belleza-peinados-recogidos-salon-ourense.jpg" class="img-4">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/trabajos/scala-peinados-&-belleza-tintes-eventos-salon-ourense.jpg" class="img-5">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
        <a href="../../../assets/img/gallery/trabajos/scala-peinados-&-belleza-tintes-eventos-salon-profecional-ourense.jpg" class="img-6">
            <mat-icon aria-hidden="false" >crop_free</mat-icon>
        </a>
    </div>
  </mat-tab>
  </mat-tab-group>
</div>
