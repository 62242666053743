<!-- <app-nav></app-nav>
<div class="container">
  <div id="home">
    <app-home></app-home>
  </div>
  <div id="nosotros">
    <app-about-us></app-about-us>
  </div>
  <div id="contacto">
    <app-contact-us></app-contact-us>
  </div>
  <div id="galeria">
    <app-gallery></app-gallery>
  </div>
</div>
<app-footer></app-footer>
<app-scroll-to-top></app-scroll-to-top>
 -->
<router-outlet style="width: 100%;"></router-outlet>
<mat-toolbar>
  <mat-toolbar-row class="legal">
    <span class="spacer"></span>
    <p>©2020 Scala</p>
    <a mat-button routerLink="legal">aviso legal</a>
  </mat-toolbar-row>
</mat-toolbar>
