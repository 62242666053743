<mat-toolbar>
  <mat-toolbar-row>
    <img src="../../../assets/img/scala-salon-peluqueria-y-bellza-logo-nav.png" alt="">
  </mat-toolbar-row>
  <mat-toolbar-row>
    <p>Tel: 988-22-60-08 - NIF: Avenida de Portugal, 172</p>
  </mat-toolbar-row>
  <mat-toolbar-row class="nav-info">
    <button mat-icon-button class="example-icon" aria-label="Facebook">
      <a href="https://www.facebook.com/ScalaPeluqueria/?ref=page_internal"><mat-icon>facebook</mat-icon></a>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Telefono">
      <a href="#contacto"><mat-icon>local_phone</mat-icon></a>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <a href="#contacto"><mat-icon>access_time</mat-icon></a>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <a href="#contacto"><mat-icon>map</mat-icon></a>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <a href="#contacto"><mat-icon>mail_outline</mat-icon></a>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <a href="#contacto"><i class="fab fa-whatsapp fa-2x"></i></a>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

